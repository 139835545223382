<template>
  <v-app>
    <v-main>
      <template v-if="user !== null && !isLoading">
        <template>
          <div class="campaign-rewards-creators-web">
            <div class="bg-main"></div>
            <div class="bg-open"></div>
            <div class="content">
              <div class="header">
                <a href="https://www.twitch.tv" target="_blank"><img class="twitch-logo" src="/imgs/twitch-logo.png" /></a>
                <div class="creator-photo">
                  <v-icon x-large></v-icon>
                  <div class="photo">
                    <v-tooltip bottom color="#9146ff" v-if="user !== null">
                      <template v-slot:activator="{ on, attrs }">
                        <v-avatar style="cursor: pointer" @click="logout" class="ml-2" v-bind="attrs" v-on="on">
                          <v-img :src="user?.profile_image_url"></v-img>
                        </v-avatar>
                      </template>
                      <span class="logout">{{ $vuetify.lang.t('$vuetify.logout') }}</span>
                    </v-tooltip>
                  </div>
                </div>
                <template v-if="user !== null">
                  <div class="creator-username" v-text="user?.display_name"></div>
                </template>
              </div>
              <div class="back-container" @click="viewSettings">
                <svg class="back-button" width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="65" height="65" rx="32.5" fill="#B2E816" />
                  <path d="M38 21L27 32L38 43" stroke="black" stroke-width="6" stroke-linecap="round" />
                </svg>
              </div>
              <div class="welcome">User Management</div>
              <div class="search-box">
                <div class="search-rewards">Search Users</div>
                <div class="search-bg">
                  <input type="text" class="search-text" v-model="couponsSearch" />
                </div>
                <svg class="search-icon" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M19.7883 21.9095C18.0354 23.2224 15.8585 24 13.5 24C7.70101 24 3 19.299 3 13.5C3 7.70101 7.70101 3 13.5 3C19.299 3 24 7.70101 24 13.5C24 15.8585 23.2224 18.0353 21.9096 19.7882L26.5608 24.4394L24.4395 26.5607L19.7883 21.9095ZM21 13.5C21 17.6421 17.6421 21 13.5 21C9.35786 21 6 17.6421 6 13.5C6 9.35786 9.35786 6 13.5 6C17.6421 6 21 9.35786 21 13.5Z"
                    fill="#D2D2E6"
                  />
                </svg>
              </div>
              <div class="rewards-page-box">
                <div class="rewards-per-page">Users per Page</div>
                <div class="page-bg">
                  <input type="number" class="page-text" v-model="pageSize" min="1" max="10" onKeyUp="if(this.value>10){this.value=10;}else if(this.value<0){this.value=0;}" />
                </div>
              </div>
              <div class="rewards-table">
                <template v-if="pageItems && pageItems.length === 0 ? true : false">
                  <div class="rewards-no-data">No staff users available</div>
                </template>
                <template v-else>
                  <div>
                    <div class="display-name">Display Name</div>
                    <div class="blocked">Blocked</div>
                    <div class="current-score">Login</div>
                    <div class="type">Type</div>
                    <div class="last-login">Role</div>
                    <div class="partner-or-affiliate">Partner or Affiliate</div>
                  </div>
                  <template v-for="(item, index) in pageItems">
                    <div :key="index">
                      <div :class="'row-bg-' + index"></div>
                      <div class="row-photo" :style="'top: ' + ((index + 1) * 130 + 472) + 'px !important'">
                        <v-avatar size="78">
                          <img alt="user" :src="item?.profile_image_url" />
                        </v-avatar>
                        <template v-if="item?.broadcaster_type == 'partner'">
                          <svg class="partner-logo" type="color-fill-current" width="28px" height="28px" version="1.1" viewBox="0 0 16 16" x="0px" y="0px">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 3.5L8 2L3.5 3.5L2 8L3.5 12.5L8 14L12.5 12.5L14 8L12.5 3.5ZM7.00008 11L11.5 6.5L10 5L7.00008 8L5.5 6.5L4 8L7.00008 11Z"></path>
                          </svg>
                        </template>
                      </div>
                      <template v-if="item?.display_name?.length > 13">
                        <v-tooltip bottom color="#9146ff">
                          <template v-slot:activator="{ on, attrs }">
                            <div class="text-truncate row-display-name" :style="'top: ' + ((index + 1) * 130 + 492) + 'px !important'" v-bind="attrs" v-on="on">{{ item?.display_name }}</div>
                          </template>
                          <span class="logout">{{ item?.display_name }}</span>
                        </v-tooltip>
                      </template>
                      <template v-else>
                        <div class="row-display-name" :style="'top: ' + ((index + 1) * 130 + 492) + 'px !important'">{{ item?.display_name }}</div>
                      </template>
                      <div class="row-blocked" :style="'top: ' + ((index + 1) * 130 + 482) + 'px !important'">
                        <v-hover>
                          <template v-slot="{ hover }">
                            <div>
                              <template v-if="item.blocked">
                                <svg class="check" width="55" height="55" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M11 27.5L24.75 41.25L46.75 19.25L42.625 15.125L24.75 33L15.125 23.375L11 27.5Z" fill="#BEFF00" />
                                </svg>
                              </template>
                              <template v-else>
                                <svg class="hide-close" width="55" height="55" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M23.375 27.5L11 15.125L15.125 11L27.5 23.375L39.875 11L44 15.125L31.625 27.5L44 39.875L39.875 44L27.5 31.625L15.125 44L11 39.875L23.375 27.5Z" fill="#BB1411" />
                                </svg>
                              </template>
                              <v-fade-transition>
                                <v-overlay class="score-overlay" v-if="hover" absolute color="#036358" style="z-index: 9999 !important; position: relative; left: -70px; top: -92px; background-color: unset !important">
                                  <v-container>
                                    <v-row>
                                      <v-col cols="12">
                                        <v-card width="280px">
                                          <v-card-text>
                                            <p>
                                              <b>User - {{ item?.display_name }}</b>
                                            </p>
                                          </v-card-text>
                                          <v-card-actions style="margin-top: -30px">
                                            <v-btn
                                              :color="!item.blocked ? 'warning' : 'success'"
                                              block
                                              @click="
                                                block({
                                                  userId: item.id,
                                                  block: !item.blocked
                                                })
                                              "
                                            >
                                              {{ item.blocked ? 'Unblock' : 'Block' }} User
                                            </v-btn>
                                          </v-card-actions>
                                        </v-card>
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-overlay>
                              </v-fade-transition>
                            </div>
                          </template>
                        </v-hover>
                      </div>
                      <template v-if="item.login?.length > 18">
                        <v-tooltip bottom color="#9146ff">
                          <template v-slot:activator="{ on, attrs }">
                            <div :class="'text-truncate row-name-' + index" style="max-width: 250px" v-bind="attrs" v-on="on">{{ item?.login }}</div>
                          </template>
                          <span class="logout">{{ item?.login }}</span>
                        </v-tooltip>
                      </template>
                      <template v-else>
                        <div :class="'text-truncate row-name-' + index" style="max-width: 250px">{{ item?.login }}</div>
                      </template>
                      <div class="row-type" :style="'top: ' + ((index + 1) * 130 + 492) + 'px !important'">
                        <template v-if="user.role === 'super'">
                          <v-hover>
                            <template v-slot="{ hover }">
                              <div>
                                {{ item.type ?? 'creator' }}
                                <v-fade-transition>
                                  <v-overlay class="score-overlay" v-if="hover" absolute color="#036358" style="z-index: 20 !important; position: relative; left: -70px; top: -74px; background-color: unset !important">
                                    <v-container>
                                      <v-row>
                                        <v-col cols="12">
                                          <v-card width="280px">
                                            <v-card-text>
                                              <p>
                                                <b>User Type - {{ item?.display_name }}</b>
                                              </p>
                                            </v-card-text>
                                            <v-card-actions style="margin-top: -30px">
                                              <v-btn color="primary" block @click="addType(item.id, item.type ?? 'creator')"> Change </v-btn>
                                            </v-card-actions>
                                          </v-card>
                                        </v-col>
                                      </v-row>
                                    </v-container>
                                  </v-overlay>
                                </v-fade-transition>
                              </div>
                            </template>
                          </v-hover>
                        </template>
                        <template v-else>
                          {{ item.type ?? 'creator' }}
                        </template>
                      </div>
                      <div class="row-last-login" :style="'top: ' + ((index + 1) * 130 + 492) + 'px !important'">
                        <v-hover>
                          <template v-slot="{ hover }">
                            <div>
                              {{ item.role ?? 'viewer' }}
                              <v-fade-transition>
                                <v-overlay class="score-overlay" v-if="hover" absolute color="#036358" style="z-index: 20 !important; position: relative; left: -70px; top: -74px; background-color: unset !important">
                                  <v-container>
                                    <v-row>
                                      <v-col cols="12">
                                        <v-card width="280px">
                                          <v-card-text>
                                            <p>
                                              <b>User Role - {{ item?.display_name }}</b>
                                            </p>
                                          </v-card-text>
                                          <v-card-actions style="margin-top: -30px">
                                            <v-btn color="primary" block @click="add(item.id, item.role ?? 'viewer')"> Change </v-btn>
                                          </v-card-actions>
                                        </v-card>
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-overlay>
                              </v-fade-transition>
                            </div>
                          </template>
                        </v-hover>
                      </div>
                      <div class="row-partner-or-affiliate" :style="'top: ' + ((index + 1) * 130 + 492) + 'px !important'">{{ item.broadcaster_type?.toUpperCase().charAt(0) || 'X' }}</div>
                    </div>
                  </template>
                  <div class="pagination" :style="'top: ' + ((pageItems.length || 1) * 130 + 660) + 'px !important'">
                    <v-pagination v-model="page" :length="pages" color="#424242"></v-pagination>
                  </div>
                </template>
              </div>
              <img class="red-bits-rewards" src="/imgs/red-bits-rewards.png" />
              <div class="no-mobile-view">Mobile Version Not Supported:<br />Please access the desktop version of GlitchEXP to continue</div>
              <div class="footer">
                <a href="https://www.twitch.tv" target="_blank"><img class="twitch-glitch-white" src="/imgs/twitch-glitch-white.png" /></a>
                <div>
                  <a class="terms-of-service" href="https://www.twitch.tv/p/en/legal/terms-of-service/" target="_blank">{{ $vuetify.lang.t('$vuetify.terms') }}</a>
                </div>
                <div class="partner">{{ $vuetify.lang.t('$vuetify.develop_by') }}</div>
                <div>
                  <a class="privacy-policy" href="https://www.twitch.tv/p/en/legal/privacy-notice/" target="_blank">{{ $vuetify.lang.t('$vuetify.privacy_policy') }}</a>
                </div>
                <div class="trade-mark">© {{ new Date().getFullYear() }} Twitch Interactive Inc</div>
              </div>
            </div>
          </div>
        </template>
        <v-dialog persistent transition="dialog-top-transition" max-width="1200" :value="change" opacity="1">
          <v-card>
            <v-toolbar>
              <v-toolbar-title> Update Role </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn color="error" rounded @click="cancel"> Cancel </v-btn>
            </v-toolbar>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-select v-model="role" :items="roles" label="Role"></v-select>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions class="text-right">
              <v-spacer></v-spacer>
              <v-btn color="primary" class="ml-2 mb-2" rounded @click="doAddFaq"> Update </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog persistent transition="dialog-top-transition" max-width="1200" :value="changeType" opacity="1">
          <v-card>
            <v-toolbar>
              <v-toolbar-title> Update Type </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn color="error" rounded @click="cancelType"> Cancel </v-btn>
            </v-toolbar>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-select v-model="type" :items="types" label="Type"></v-select>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions class="text-right">
              <v-spacer></v-spacer>
              <v-btn color="primary" class="ml-2 mb-2" rounded @click="doAddType"> Update </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-snackbar top color="green" v-model="success" :timeout="5000">
          <v-icon>mdi-check</v-icon>
          <v-divider class="mx-1" vertical></v-divider>
          <strong>User role {{ action }}ed successfully!</strong>
          <v-divider class="mx-3" vertical></v-divider>
          <v-btn color="green" @click="success = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-snackbar>
        <v-snackbar top color="red" min-width="720px" v-model="error" :timeout="5000">
          <v-icon>mdi-check</v-icon>
          <v-divider class="mx-1" vertical></v-divider>
          <strong> An error occurred while {{ action }}ing the user role. Please check your data and try again. </strong>
          <v-divider class="mx-3" vertical></v-divider>
          <v-btn color="red" @click="error = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-snackbar>
      </template>
      <template v-else>
        <v-overlay>
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </template>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { sortBy } from 'lodash'

export default {
  data() {
    return {
      campaignTabs: 0,
      page: 1,
      pageSize: 10,
      showSearch: '',
      showLoggedIn: true,
      showNotLoggedIn: true,
      showOverlayUsed: true,
      showOverlayNotUsed: true,
      showMenu: false,
      policy: false,
      viewChannelsDialog: false,
      users: [],
      newChannelDialog: false,
      newChannelCampaign: null,
      newChannelSearch: '',
      importText: '',
      isLoading: true,
      isLoadingCoupons: true,
      isSearch: true,
      overlay: false,
      startDate: Date.now(),
      endDate: Date.now(),
      startTime: null,
      endTime: null,
      startDateMenu: false,
      endDateMenu: false,
      startTimeMenu: false,
      endTimeMenu: false,
      name: '',
      includeSubs: true,
      includeBits: true,
      includeRaids: false,
      raidTimezone: '',
      raidTag: '',
      raidLevel: '',
      tier1: 0,
      tier2: 0,
      tier3: 0,
      tier4: 0,
      tier5: 0,
      tier6: 0,
      tier7: 0,
      tier8: 0,
      tier9: 0,
      tier10: 0,
      tier11: 0,
      tier12: 0,
      rules: [(v) => v < 2147483647 || 'Max should not be above 2,147,483,647'],
      link: process.env.VUE_APP_BASE_API_URL,
      raidLevels: [
        { name: 'Partner only', value: 'partner' },
        { name: 'Partner & Affiliate only', value: 'affiliate' },
        { name: 'All', value: 'all' }
      ],
      languages: [
        { name: 'Abkhazian', value: 'ab' },
        { name: 'Afar', value: 'aa' },
        { name: 'Afrikaans', value: 'af' },
        { name: 'Akan', value: 'ak' },
        { name: 'Albanian', value: 'sq' },
        { name: 'Amharic', value: 'am' },
        { name: 'Arabic', value: 'ar' },
        { name: 'Aragonese', value: 'an' },
        { name: 'Armenian', value: 'hy' },
        { name: 'Assamese', value: 'as' },
        { name: 'Avaric', value: 'av' },
        { name: 'Avestan', value: 'ae' },
        { name: 'Aymara', value: 'ay' },
        { name: 'Azerbaijani', value: 'az' },
        { name: 'Bambara', value: 'bm' },
        { name: 'Bashkir', value: 'ba' },
        { name: 'Basque', value: 'eu' },
        { name: 'Belarusian', value: 'be' },
        { name: 'Bengali', value: 'bn' },
        { name: 'Bislama', value: 'bi' },
        { name: 'Bosnian', value: 'bs' },
        { name: 'Breton', value: 'br' },
        { name: 'Bulgarian', value: 'bg' },
        { name: 'Burmese', value: 'my' },
        { name: 'Catalan, Valencian', value: 'ca' },
        { name: 'Chamorro', value: 'ch' },
        { name: 'Chechen', value: 'ce' },
        { name: 'Chichewa, Chewa, Nyanja', value: 'ny' },
        { name: 'Chinese', value: 'zh' },
        {
          name: 'Church Slavic, Old Slavonic, Church Slavonic, Old Bulgarian, Old Church Slavonic',
          value: 'cu'
        },
        { name: 'Chuvash', value: 'cv' },
        { name: 'Cornish', value: 'kw' },
        { name: 'Corsican', value: 'co' },
        { name: 'Cree', value: 'cr' },
        { name: 'Croatian', value: 'hr' },
        { name: 'Czech', value: 'cs' },
        { name: 'Danish', value: 'da' },
        { name: 'Divehi, Dhivehi, Maldivian', value: 'dv' },
        { name: 'Dutch, Flemish', value: 'nl' },
        { name: 'Dzongkha', value: 'dz' },
        { name: 'English', value: 'en' },
        { name: 'Esperanto', value: 'eo' },
        { name: 'Estonian', value: 'et' },
        { name: 'Ewe', value: 'ee' },
        { name: 'Faroese', value: 'fo' },
        { name: 'Fijian', value: 'fj' },
        { name: 'Finnish', value: 'fi' },
        { name: 'French', value: 'fr' },
        { name: 'Western Frisian', value: 'fy' },
        { name: 'Fulah', value: 'ff' },
        { name: 'Gaelic, Scottish Gaelic', value: 'gd' },
        { name: 'Galician', value: 'gl' },
        { name: 'Ganda', value: 'lg' },
        { name: 'Georgian', value: 'ka' },
        { name: 'German', value: 'de' },
        { name: 'Greek, Modern (1453–)', value: 'el' },
        { name: 'Kalaallisut, Greenlandic', value: 'kl' },
        { name: 'Guarani', value: 'gn' },
        { name: 'Gujarati', value: 'gu' },
        { name: 'Haitian, Haitian Creole', value: 'ht' },
        { name: 'Hausa', value: 'ha' },
        { name: 'Hebrew', value: 'he' },
        { name: 'Herero', value: 'hz' },
        { name: 'Hindi', value: 'hi' },
        { name: 'Hiri Motu', value: 'ho' },
        { name: 'Hungarian', value: 'hu' },
        { name: 'Icelandic', value: 'is' },
        { name: 'Ido', value: 'io' },
        { name: 'Igbo', value: 'ig' },
        { name: 'Indonesian', value: 'id' },
        {
          name: 'Interlingua (International Auxiliary Language Association)',
          value: 'ia'
        },
        { name: 'Interlingue, Occidental', value: 'ie' },
        { name: 'Inuktitut', value: 'iu' },
        { name: 'Inupiaq', value: 'ik' },
        { name: 'Irish', value: 'ga' },
        { name: 'Italian', value: 'it' },
        { name: 'Japanese', value: 'ja' },
        { name: 'Javanese', value: 'jv' },
        { name: 'Kannada', value: 'kn' },
        { name: 'Kanuri', value: 'kr' },
        { name: 'Kashmiri', value: 'ks' },
        { name: 'Kazakh', value: 'kk' },
        { name: 'Central Khmer', value: 'km' },
        { name: 'Kikuyu, Gikuyu', value: 'ki' },
        { name: 'Kinyarwanda', value: 'rw' },
        { name: 'Kirghiz, Kyrgyz', value: 'ky' },
        { name: 'Komi', value: 'kv' },
        { name: 'Kongo', value: 'kg' },
        { name: 'Korean', value: 'ko' },
        { name: 'Kuanyama, Kwanyama', value: 'kj' },
        { name: 'Kurdish', value: 'ku' },
        { name: 'Lao', value: 'lo' },
        { name: 'Latin', value: 'la' },
        { name: 'Latvian', value: 'lv' },
        { name: 'Limburgan, Limburger, Limburgish', value: 'li' },
        { name: 'Lingala', value: 'ln' },
        { name: 'Lithuanian', value: 'lt' },
        { name: 'Luba-Katanga', value: 'lu' },
        { name: 'Luxembourgish, Letzeburgesch', value: 'lb' },
        { name: 'Macedonian', value: 'mk' },
        { name: 'Malagasy', value: 'mg' },
        { name: 'Malay', value: 'ms' },
        { name: 'Malayalam', value: 'ml' },
        { name: 'Maltese', value: 'mt' },
        { name: 'Manx', value: 'gv' },
        { name: 'Maori', value: 'mi' },
        { name: 'Marathi', value: 'mr' },
        { name: 'Marshallese', value: 'mh' },
        { name: 'Mongolian', value: 'mn' },
        { name: 'Nauru', value: 'na' },
        { name: 'Navajo, Navaho', value: 'nv' },
        { name: 'North Ndebele', value: 'nd' },
        { name: 'South Ndebele', value: 'nr' },
        { name: 'Ndonga', value: 'ng' },
        { name: 'Nepali', value: 'ne' },
        { name: 'Norwegian', value: 'no' },
        { name: 'Norwegian Bokmål', value: 'nb' },
        { name: 'Norwegian Nynorsk', value: 'nn' },
        { name: 'Sichuan Yi, Nuosu', value: 'ii' },
        { name: 'Occitan', value: 'oc' },
        { name: 'Ojibwa', value: 'oj' },
        { name: 'Oriya', value: 'or' },
        { name: 'Oromo', value: 'om' },
        { name: 'Ossetian, Ossetic', value: 'os' },
        { name: 'Pali', value: 'pi' },
        { name: 'Pashto, Pushto', value: 'ps' },
        { name: 'Persian', value: 'fa' },
        { name: 'Polish', value: 'pl' },
        { name: 'Portuguese', value: 'pt' },
        { name: 'Punjabi, Panjabi', value: 'pa' },
        { name: 'Quechua', value: 'qu' },
        { name: 'Romanian, Moldavian, Moldovan', value: 'ro' },
        { name: 'Romansh', value: 'rm' },
        { name: 'Rundi', value: 'rn' },
        { name: 'Russian', value: 'ru' },
        { name: 'Northern Sami', value: 'se' },
        { name: 'Samoan', value: 'sm' },
        { name: 'Sango', value: 'sg' },
        { name: 'Sanskrit', value: 'sa' },
        { name: 'Sardinian', value: 'sc' },
        { name: 'Serbian', value: 'sr' },
        { name: 'Shona', value: 'sn' },
        { name: 'Sindhi', value: 'sd' },
        { name: 'Sinhala, Sinhalese', value: 'si' },
        { name: 'Slovak', value: 'sk' },
        { name: 'Slovenian', value: 'sl' },
        { name: 'Somali', value: 'so' },
        { name: 'Southern Sotho', value: 'st' },
        { name: 'Spanish, Castilian', value: 'es' },
        { name: 'Sundanese', value: 'su' },
        { name: 'Swahili', value: 'sw' },
        { name: 'Swati', value: 'ss' },
        { name: 'Swedish', value: 'sv' },
        { name: 'Tagalog', value: 'tl' },
        { name: 'Tahitian', value: 'ty' },
        { name: 'Tajik', value: 'tg' },
        { name: 'Tamil', value: 'ta' },
        { name: 'Tatar', value: 'tt' },
        { name: 'Telugu', value: 'te' },
        { name: 'Thai', value: 'th' },
        { name: 'Tibetan', value: 'bo' },
        { name: 'Tigrinya', value: 'ti' },
        { name: 'Tonga (Tonga Islands)', value: 'to' },
        { name: 'Tsonga', value: 'ts' },
        { name: 'Tswana', value: 'tn' },
        { name: 'Turkish', value: 'tr' },
        { name: 'Turkmen', value: 'tk' },
        { name: 'Twi', value: 'tw' },
        { name: 'Uighur, Uyghur', value: 'ug' },
        { name: 'Ukrainian', value: 'uk' },
        { name: 'Urdu', value: 'ur' },
        { name: 'Uzbek', value: 'uz' },
        { name: 'Venda', value: 've' },
        { name: 'Vietnamese', value: 'vi' },
        { name: 'Volapük', value: 'vo' },
        { name: 'Walloon', value: 'wa' },
        { name: 'Welsh', value: 'cy' },
        { name: 'Wolof', value: 'wo' },
        { name: 'Xhosa', value: 'xh' },
        { name: 'Yiddish', value: 'yi' },
        { name: 'Yoruba', value: 'yo' },
        { name: 'Zhuang, Chuang', value: 'za' },
        { name: 'Zulu', value: 'zu' }
      ],
      coupons: [],
      couponsSearch: '',
      couponsPage: 1,
      couponsPerPage: 10,
      couponsPageCount: 0,
      couponSuccess: false,
      couponsDialogDelete: false,
      couponsHeaders: [
        { text: 'Coupon Code', value: 'code', align: 'start' },
        { text: 'User ID', value: 'user_id', align: 'start' },
        { text: 'User Name', value: 'user_name' },
        { text: 'Digital Reward Name', value: 'name' },
        { text: 'Digital Reward Mechanics', value: 'mechanics' },
        { text: 'Tier', value: 'tier_level' },
        { text: 'Send (Live/Post)', value: 'live' },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      editedIndex: -1,
      editedItem: {
        id: null
      },
      roles: ['super', 'admin', 'manager', 'viewer'],
      role: 'viewer',
      change: false,
      types: ['staff', 'creator'],
      type: 'creator',
      changeType: false,
      userId: null,
      success: false,
      error: false,
      action: 'update'
    }
  },
  watch: {
    includeRaids(newValue) {
      this.includeSubs = !newValue
      this.includeBits = !newValue
    },
    showOverlayUsed() {
      this.page = 1
    },
    showOverlayNotUsed() {
      this.page = 1
    },
    showLoggedIn() {
      this.page = 1
    },
    showNotLoggedIn() {
      this.page = 1
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
    couponsPerPage(newValue) {
      this.couponsPage = 1
    }
  },
  computed: {
    ...mapGetters(['user', 'staff']),
    pages() {
      return this.itemCount < this.pageSize ? 1 : Math.ceil(this.itemCount / this.pageSize)
    },
    pageItems() {
      const items = this.items?.slice((this.page - 1) * this.pageSize, this.page * this.pageSize) ?? []
      return items
    },
    itemCount() {
      return this.items?.length ?? 0
    },
    items() {
      const filtered = this.users ?? []
      return filtered.filter((item) => {
        let returnItem = true
        if (this.couponsSearch.length > 0) {
          returnItem = item?.display_name.toLowerCase()?.includes(this.couponsSearch.toLowerCase()) === true
        }
        return returnItem
      })
    },
    filteredCoupons() {
      let filtered = this.coupons
      if (this.couponsSearch.length > 0) {
        filtered = this.coupons.filter((coupon) => coupon.code.includes(this.couponsSearch) || Number(coupon.tier_level) === Number(this.couponsSearch))
      }
      const sorted = sortBy(filtered, ['tier_level', 'code'], ['asc', 'asc'])
      return sorted.slice((this.couponsPage - 1) * this.couponsPerPage, this.couponsPage * this.couponsPerPage) ?? 0
    },
    numberOfPages() {
      let filtered = this.coupons
      if (this.couponsSearch.length > 0) {
        filtered = this.coupons.filter((coupon) => coupon.code.includes(this.couponsSearch) || Number(coupon.tier_level) === Number(this.couponsSearch))
      }
      const sorted = sortBy(filtered, ['tier_level', 'code'], ['asc', 'asc'])
      return sorted.length < this.couponsPerPage ? 1 : Math.ceil(sorted.length / this.couponsPerPage)
    }
  },
  methods: {
    ...mapActions(['loadSystem', 'signOut', 'getStaffUsers', 'updateUserRole', 'updateUserType', 'blockUser']),
    viewSettings() {
      this.$router.push({ name: 'settings' })
    },
    logout() {
      this.signOut().then(() => {
        this.$router.push('/')
      })
    },
    nextPage() {
      if (this.couponsPage + 1 <= this.numberOfPages) this.couponsPage += 1
    },
    formerPage() {
      if (this.couponsPage - 1 >= 1) this.couponsPage -= 1
    },
    cancel() {
      this.change = false
      this.userId = null
      this.role = 'viewer'
    },
    cancelType() {
      this.changeType = false
      this.userId = null
      this.type = ''
    },
    add(userId, role) {
      this.change = true
      this.userId = userId
      this.role = role
    },
    addType(userId, type) {
      this.changeType = true
      this.userId = userId
      this.type = type
    },
    doAddFaq() {
      this.action = 'update'
      this.isLoading = true
      this.success = false
      this.error = false
      this.change = false
      this.updateUserRole({
        userId: this.userId,
        role: this.role
      })
        .then((_) => {
          this.success = true
        })
        .catch((_) => {
          this.error = true
        })
        .finally((_) => {
          this.getStaffUsers().then((result) => {
            this.users = result
            this.isLoading = false
            this.cancel()
          })
        })
    },
    doAddType() {
      this.action = 'update'
      this.isLoading = true
      this.success = false
      this.error = false
      this.changeType = false
      this.updateUserType({
        userId: this.userId,
        type: this.type
      })
        .then((_) => {
          this.success = true
        })
        .catch((_) => {
          this.error = true
        })
        .finally((_) => {
          this.getStaffUsers().then((result) => {
            this.users = result
            this.isLoading = false
            this.cancelType()
          })
        })
    },
    block(user) {
      if (confirm(`Are you sure you want to ${user.block ? 'block' : 'unblock'} this user?`)) {
        this.isLoading = true
        this.blockUser(user).then(() => {
          this.getStaffUsers().then((result) => {
            this.users = result
            this.isLoading = false
          })
        })
      }
    }
  },
  mounted() {
    if (process.env.VUE_APP_NO_PROD_REDIRECT !== 'true') {
      location.replace('https://glitch-exp.com')
    }
    this.$vuetify.lang.current = localStorage.language ?? 'en'
    if (this.user == null) {
      this.isLoading = true
      this.loadSystem().then((_) => {
        if (this.user?.role?.toLowerCase() !== 'super') {
          this.$router.push({ name: 'home' })
        } else {
          if (this.users.length === 0) {
            this.getStaffUsers().then((result) => {
              this.users = result
              this.isLoading = false
            })
          } else {
            this.isLoading = false
          }
        }
      })
    } else {
      if (this.user?.role?.toLowerCase() !== 'super') {
        this.$router.push({ name: 'home' })
      } else {
        if (this.users.length === 0) {
          this.getStaffUsers().then((result) => {
            this.users = result
            this.isLoading = false
          })
        } else {
          this.isLoading = false
        }
      }
    }
  }
}
</script>

<style>
body {
  background: #121212 !important;
}
</style>

<style lang="scss" scoped>
.partner-logo {
  color: #bf94ff;
  fill: #bf94ff;
  position: relative;
  left: -20px;
  top: -20px;
}

.rewards {
  margin-top: 12px;
}

.delete {
  margin-left: 10px;
}

.campaign-rewards-creators-web,
.campaign-rewards-creators-web * {
  box-sizing: border-box;
}
.campaign-rewards-creators-web {
  background: #000000 !important;
  width: 100% !important;
  height: 2292px;
  position: relative;
  overflow: hidden;

  @media only screen and (max-height: 900px) {
    height: 1000px;
  }
}
.bg-main {
  background: linear-gradient(180deg, #e3ff93 0%, #c1ff0c 19.27%);
  width: 100%;
  height: 1074px;
  position: absolute;
  left: 0.46px;
  top: -3px;
  @media only screen and (max-height: 900px) {
    height: 1000px;
  }
}
.bg-open {
  background: #000000;
  border-radius: 100px 100px 0px 0px;
  width: 100%;
  height: 1103px;
  position: absolute;
  left: 0.46px;
  top: 305px;
  box-shadow: 0px -40px 44px 0px rgba(40, 48, 18, 0.25);
  @media only screen and (max-height: 900px) {
    background: #000000;
    border-radius: 0px 0px 0px 0px;
    width: 395px;
    height: 150px;
    position: absolute;
    left: 0px;
    top: 850px;
  }
}
.header {
  position: absolute;
  inset: 0;
}
.creator-photo {
  width: 42px;
  height: 42px;
  position: static;
}
.photo {
  width: 42px;
  height: 42px;
  position: absolute;
  right: 70px;
  top: 60px;
  z-index: 10;

  @media only screen and (max-height: 900px) {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    position: absolute;
    right: 44px;
    top: 44px;
    z-index: 1;
  }
}
.logout {
  color: var(--black, #000000);
  font: 600 20px/18px 'RoobertRegular';

  @media only screen and (max-height: 900px) {
    z-index: 1;
  }
}
.creator-username {
  color: var(--black, #000000);
  text-align: center;
  font: 600 20px/18px 'RoobertRegular';
  position: absolute;
  right: 130px;
  top: 63px;
  width: 372px;
  height: 41px;
  display: flex;
  align-items: center;
  justify-content: right;

  @media only screen and (max-height: 900px) {
    text-align: center;
    font: 600 14px/18px 'RoobertRegular', sans-serif;
    position: absolute;
    right: 70px;
    top: 64px;
    width: 124px;
    height: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.twitch-logo {
  width: 131px;
  height: 44px;
  position: absolute;
  left: 99.46px;
  top: 61px;
  z-index: 10;
  cursor: pointer;

  @media only screen and (max-height: 900px) {
    width: 54px;
    height: 18px;
    left: 18px;
  }
}
.content {
  margin: auto;
  padding: 12px;
  min-width: 1420px;
  max-width: 1920px;

  @media only screen and (max-height: 900px) {
    max-height: 900px;
  }
}
.back-container {
  visibility: visible;
  @media only screen and (max-height: 900px) {
    visibility: hidden;
  }
}
.back-container-mobile {
  visibility: hidden;
  @media only screen and (max-height: 900px) {
    visibility: visible;
  }
}
.back-button {
  box-sizing: border-box;
  position: relative;
  overflow: visible;
  left: calc(50% - 730px);
  top: 180px;
  cursor: pointer;
  z-index: 1;

  @media only screen and (max-height: 900px) {
    left: -4px;
    top: 24px;
  }
}
.back-button:hover {
  rect {
    fill: #7d5bbe;
  }
}
.welcome {
  color: #000000;
  text-align: left;
  font-style: normal;
  font-weight: 700;
  font-size: 85px;
  line-height: 106px;
  position: absolute;
  left: calc(50% - 623.54px);
  top: 167.4px;

  @media only screen and (max-height: 900px) {
    visibility: hidden;
  }
}
.search-box-mobile {
  box-sizing: border-box;
  position: absolute;
  inset: 0;
  visibility: hidden;
  @media only screen and (max-height: 900px) {
    visibility: visible;
  }
}
.search-rewards-mobile {
  color: var(--color-brand-muted-smoke, #d2d2e6);
  text-align: left;
  font: 600 11px/23.52px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 43px;
  top: 170px;
  width: 168px;
  height: 18px;
}
.search-bg-mobile {
  background: #222222;
  border-radius: 5px;
  border-style: solid;
  border-color: #353535;
  border-width: 1px;
  width: 94px;
  height: 20px;
  position: absolute;
  left: 43px;
  top: 191px;
}
.search-text-mobile {
  color: var(--color-brand-muted-smoke, #d2d2e6);
  font: 500 11px/23.52px 'RoobertRegular', sans-serif;
  margin-left: 20px;
  width: 70px;
  z-index: 100 !important;
  position: absolute;
  top: -3px;
}
.search {
  position: absolute;
  left: 47px;
  top: 194px;
  overflow: visible;
}
.search-box {
  visibility: visible;
  @media only screen and (max-height: 900px) {
    visibility: hidden;
  }
}
.search-rewards {
  color: var(--color-brand-muted-smoke, #d2d2e6);
  text-align: left;
  font: 600 25px/23.52px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 630px);
  top: 388px;
  width: 351px;
}
.search-bg {
  background: #222222;
  border-radius: 10px;
  border-style: solid;
  border-color: #353535;
  border-width: 2px;
  width: 419px;
  height: 50px;
  position: absolute;
  left: calc(50% - 630px);
  top: 427px;
  z-index: 1;
}
.search-icon {
  position: absolute;
  left: calc(50% - 621px);
  top: 437px;
  overflow: visible;
  z-index: 1;
}
.search-text {
  color: var(--color-brand-muted-smoke, #d2d2e6);
  font: 500 26px/64.44px 'RoobertRegular', sans-serif;
  margin-left: 50px;
  margin-top: -10px;
  width: 350px;
  z-index: 100 !important;
}
.rewards-page-box {
  visibility: visible;
  @media only screen and (max-height: 900px) {
    visibility: hidden;
  }
}
.rewards-per-page {
  color: var(--color-brand-muted-smoke, #d2d2e6);
  text-align: left;
  font: 600 25px/23.52px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 430px);
  top: 388px;
  width: 302px;
}
.page-bg {
  background: #222222;
  border-radius: 10px;
  border-style: solid;
  border-color: #353535;
  border-width: 2px;
  width: 202px;
  height: 50px;
  position: absolute;
  left: calc(50% + 430px);
  top: 427px;
  z-index: 1;
}
.page-text {
  color: var(--color-brand-muted-smoke, #d2d2e6);
  text-align: left;
  font: 500 26px/64.44px 'RoobertRegular', sans-serif;
  margin-left: 10px;
  width: 202px;
  height: 47px;
}
.rewards-page-box-mobile {
  box-sizing: border-box;
  position: absolute;
  inset: 0;

  visibility: hidden;
  @media only screen and (max-height: 900px) {
    visibility: visible;
  }
}
.rewards-per-part {
  color: var(--color-brand-muted-smoke, #d2d2e6);
  text-align: right;
  font: 600 11px/23.52px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 185px;
  top: 170px;
  width: 163px;
  height: 19px;
}
.page-bg-mobile {
  background: #222222;
  border-radius: 5px;
  border-style: solid;
  border-color: #353535;
  border-width: 1px;
  width: 93px;
  height: 20px;
  position: absolute;
  left: 255px;
  top: 191px;
}
.page-text-mobile {
  color: var(--color-brand-muted-smoke, #d2d2e6);
  text-align: left;
  font: 500 12px/64.44px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 4px;
  top: -4px;
  width: 86px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  z-index: 100;
}
.download-button {
  box-sizing: border-box;
  background: #beff00;
  border-radius: 326.53px;
  padding: 14px 30px 14px 30px;
  display: flex;
  flex-direction: column;
  gap: 42.45px;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 480px;
  left: calc(50% - 630px);
  top: 404px;
  cursor: pointer;
  z-index: 1;

  @media only screen and (max-height: 900px) {
    background: #beff00;
    border-radius: 326.53px;
    padding: 1px 10px 1px 10px;
    display: flex;
    flex-direction: column;
    gap: 42.45px;
    align-items: center;
    justify-content: center;
    height: 25px;
    left: 44px;
    width: 200px;
  }
}
.download-button:hover {
  background: #7d5bbe;
}
.download-text {
  color: var(--black-ops, #000000);
  text-align: center;
  font: 600 34.21px/42.45px 'RoobertRegular', sans-serif;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-height: 900px) {
    text-align: center;
    font: 500 14px/42.45px 'RoobertRegular', sans-serif;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.footer {
  position: absolute;
  inset: 0;
}
.twitch-glitch-white {
  z-index: 1;
  width: 64px;
  height: 75px;
  position: absolute;
  left: 98px;
  top: 2042px;

  @media only screen and (max-height: 900px) {
    width: 20px;
    height: 24px;
    left: 20px;
    top: unset;
    bottom: 104px !important;
  }
}
.terms-of-service {
  color: #ffffff;
  text-align: center;
  font: 500 20px/37.23px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 100px;
  top: 2190px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;

  @media only screen and (max-height: 900px) {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 37px;
    left: 20px;
    top: unset;
    bottom: 57px !important;
  }
}
.partner {
  color: #b3b3b3;
  text-align: right;
  font: 500 20px/37.23px 'RoobertRegular', sans-serif;
  position: absolute;
  top: 2190px;
  right: 98px;
  width: 984px;
  align-items: right;

  @media only screen and (max-height: 900px) {
    font-style: normal;
    font-weight: 500;
    font-size: 8px;
    line-height: 14px;
    right: 20px;
    top: unset;
    bottom: 36px !important;
    min-width: 200px;
    max-width: 220px;
  }
}
.privacy-policy {
  color: #ffffff;
  text-align: center;
  font: 500 20px/37.23px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 330px;
  top: 2190px;
  display: flex;
  justify-content: left;
  text-decoration: none;

  @media only screen and (max-height: 900px) {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 37px;
    left: 20px;
    top: unset;
    bottom: 36px !important;
  }
}
.trade-mark {
  color: #ffffff;
  text-align: center;
  font: 500 20px/37.23px 'RoobertRegular', sans-serif;
  position: absolute;
  top: 2154px;
  right: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-height: 900px) {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 37px;
    right: 20px;
    top: unset;
    bottom: 57px !important;
  }
}
.red-bits-rewards {
  width: 767.91px;
  height: 785px;
  position: absolute;
  right: -300px;
  top: 1560px;
  transform-origin: 0 0;
  transform: rotate(-1.9deg) scale(1, 1);

  @media only screen and (max-height: 900px) {
    visibility: hidden;
  }
}
/* ----------------------- */
/*      Rewards Table      */
/* ----------------------- */
.rewards-table {
  visibility: visible;
  @media only screen and (max-height: 900px) {
    visibility: hidden;
  }
}
.pagination {
  position: relative;
  margin: auto;
  width: 50%;
  padding-left: 50px;
  z-index: 1;

  @media only screen and (max-height: 900px) {
    position: absolute;
    left: 76px;
  }
}
.tier {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 21.87px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 560px);
  top: 533px;
  width: 169px;
}
.coupon-code {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 21.87px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 350px);
  top: 533px;
  width: 269px;
}
.digital-reward-name {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 21.87px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 80px);
  top: 533px;
  width: 236px;
}
.digital-reward-mechanics {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 21.87px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 260px);
  top: 533px;
  width: 262px;
}
.rewards-no-data {
  text-align: left;
  color: #f0f0ff;
  text-align: center;
  font-size: 60px;
  font-family: 'RoobertRegular', sans-serif;
  font-weight: 600;
  line-height: 64.438px;
  position: absolute;
  left: calc(50% - 696px);
  top: 524px;
  width: 675px;
  height: 51px;
  z-index: 10;

  @media only screen and (max-height: 900px) {
    visibility: hidden;
  }
}
.row-bg-0 {
  background: #111111;
  border-radius: 111.98px;
  width: 1272px;
  height: 105px;
  position: absolute;
  left: calc(50% - 630px);
  top: 588px;
  z-index: 0;
}
.row-bg-1 {
  background: #111111;
  border-radius: 111.98px;
  width: 1272px;
  height: 105px;
  position: absolute;
  left: calc(50% - 630px);
  top: 718px;
  z-index: 0;
}
.row-bg-2 {
  background: #111111;
  border-radius: 111.98px;
  width: 1272px;
  height: 105px;
  position: absolute;
  left: calc(50% - 630px);
  top: 848px;
  z-index: 0;
}
.row-bg-3 {
  background: #111111;
  border-radius: 111.98px;
  width: 1272px;
  height: 105px;
  position: absolute;
  left: calc(50% - 630px);
  top: 978px;
  z-index: 0;
}
.row-bg-4 {
  background: #111111;
  border-radius: 111.98px;
  width: 1272px;
  height: 105px;
  position: absolute;
  left: calc(50% - 630px);
  top: 1108px;
  z-index: 0;
}
.row-bg-5 {
  background: #111111;
  border-radius: 111.98px;
  width: 1272px;
  height: 105px;
  position: absolute;
  left: calc(50% - 630px);
  top: 1238px;
  z-index: 0;
}
.row-bg-6 {
  background: #111111;
  border-radius: 111.98px;
  width: 1272px;
  height: 105px;
  position: absolute;
  left: calc(50% - 630px);
  top: 1368px;
  z-index: 0;
}
.row-bg-7 {
  background: #111111;
  border-radius: 111.98px;
  width: 1272px;
  height: 105px;
  position: absolute;
  left: calc(50% - 630px);
  top: 1498px;
  z-index: 0;
}
.row-bg-8 {
  background: #111111;
  border-radius: 111.98px;
  width: 1272px;
  height: 105px;
  position: absolute;
  left: calc(50% - 630px);
  top: 1628px;
  z-index: 0;
}
.row-bg-9 {
  background: #111111;
  border-radius: 111.98px;
  width: 1272px;
  height: 105px;
  position: absolute;
  left: calc(50% - 630px);
  top: 1758px;
  z-index: 0;
}
.row-tier-0 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 560px);
  top: 624px;
  width: 154px;
}
.row-tier-1 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 560px);
  top: 754px;
  width: 185px;
}
.row-tier-2 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 560px);
  top: 884px;
  width: 185px;
}
.row-tier-3 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 560px);
  top: 1014px;
  width: 185px;
}
.row-tier-4 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 560px);
  top: 1144px;
  width: 185px;
}
.row-tier-5 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 560px);
  top: 1274px;
  width: 185px;
}
.row-tier-6 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 560px);
  top: 1404px;
  width: 185px;
}
.row-tier-7 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 560px);
  top: 1534px;
  width: 185px;
}
.row-tier-8 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 560px);
  top: 1664px;
  width: 185px;
}
.row-tier-9 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 560px);
  top: 1794px;
  width: 185px;
}
.row-code-0 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 350px);
  top: 624px;
  width: 250px;
  z-index: 1;
}
.row-code-1 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 350px);
  top: 754px;
  width: 250px;
  height: 39px;
  z-index: 1;
}
.row-code-2 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 350px);
  top: 880px;
  width: 250px;
  height: 53px;
  z-index: 1;
}
.row-code-3 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 350px);
  top: 1014px;
  width: 250px;
  height: 53px;
  z-index: 1;
}
.row-code-4 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 350px);
  top: 1144px;
  width: 250px;
  height: 53px;
  z-index: 1;
}
.row-code-5 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 350px);
  top: 1275px;
  width: 250px;
  height: 53px;
  z-index: 1;
}
.row-code-6 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 350px);
  top: 1404px;
  width: 250px;
  height: 53px;
  z-index: 1;
}
.row-code-7 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 350px);
  top: 1534px;
  width: 250px;
  height: 53px;
  z-index: 1;
}
.row-code-8 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 350px);
  top: 1664px;
  width: 250px;
  height: 53px;
  z-index: 1;
}
.row-code-9 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 350px);
  top: 1794px;
  width: 250px;
  height: 53px;
  z-index: 1;
}
.row-name-0 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 60px);
  top: 625px;
  width: 250px;
  height: 53px;
  z-index: 1;
}
.row-name-1 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 60px);
  top: 754px;
  width: 250px;
  height: 27px;
  z-index: 1;
}
.row-name-2 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 60px);
  top: 880px;
  width: 250px;
  height: 53px;
  z-index: 1;
}
.row-name-3 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 60px);
  top: 1014px;
  width: 250px;
  height: 53px;
  z-index: 1;
}
.row-name-4 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 60px);
  top: 1144px;
  width: 250px;
  height: 53px;
  z-index: 1;
}
.row-name-5 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 60px);
  top: 1275px;
  width: 250px;
  height: 53px;
  z-index: 1;
}
.row-name-6 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 60px);
  top: 1404px;
  width: 250px;
  height: 53px;
  z-index: 1;
}
.row-name-7 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 60px);
  top: 1534px;
  width: 250px;
  height: 53px;
  z-index: 1;
}
.row-name-8 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 60px);
  top: 1664px;
  width: 250px;
  height: 53px;
  z-index: 1;
}
.row-name-9 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 60px);
  top: 1794px;
  width: 250px;
  height: 53px;
  z-index: 1;
}
.row-mechanics-0 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 260px);
  top: 625px;
  width: 320px;
  height: 53px;
  z-index: 1;
}
.row-mechanics-1 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 260px);
  top: 754px;
  width: 320px;
  height: 53px;
  z-index: 1;
}
.row-mechanics-2 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 260px);
  top: 881px;
  width: 320px;
  height: 53px;
  z-index: 1;
}
.row-mechanics-3 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 260px);
  top: 1015px;
  width: 320px;
  height: 53px;
  z-index: 1;
}
.row-mechanics-4 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 260px);
  top: 1145px;
  width: 320px;
  height: 53px;
  z-index: 1;
}
.row-mechanics-5 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 260px);
  top: 1276px;
  width: 320px;
  height: 53px;
  z-index: 1;
}
.row-mechanics-6 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 260px);
  top: 1405px;
  width: 320px;
  height: 53px;
  z-index: 1;
}
.row-mechanics-7 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 260px);
  top: 1535px;
  width: 320px;
  height: 53px;
  z-index: 1;
}
.row-mechanics-8 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 260px);
  top: 1665px;
  width: 320px;
  height: 53px;
  z-index: 1;
}
.row-mechanics-9 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 260px);
  top: 1795px;
  width: 320px;
  height: 53px;
  z-index: 1;
}
.rewards-table-mobile {
  visibility: hidden;
  @media only screen and (max-height: 900px) {
    visibility: visible;
  }
}
.row-mobile {
  box-sizing: border-box;
  position: absolute;
  inset: 0;
}
.row-bg-mobile {
  background: #111111;
  border-radius: 10px;
  width: 306px;
  height: 132px;
  position: absolute;
  left: 43px;
  top: 232px;
}
.row-container-mobile {
  width: 281px;
  height: 97px;
  position: static;
}
.row-reward-name {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 11px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 62px;
  top: 302px;
  width: 141px;
}
.row-rewards-mechanics {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 11px/92% 'RoobertRegular', sans-serif;
  position: absolute;
  left: 223px;
  top: 304px;
  width: 127px;
}
.row-tier {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 11px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 62px;
  top: 253px;
  width: 90px;
  height: 21px;
}
.row-tier-value {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 14px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 62px;
  top: 272px;
  width: 90px;
  height: 21px;
}
.row-name-value {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 14px/92% 'RoobertRegular', sans-serif;
  position: absolute;
  left: 62px;
  top: 329px;
  width: 138px;
  height: 21px;
}
.row-coupon-code {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 11px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 224px;
  top: 253px;
  width: 127px;
  height: 22px;
}
.row-code-value {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 14px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 223px;
  top: 272px;
  width: 120px;
  height: 22px;
}
.row-mechanics-value {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 14px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 223px;
  top: 324px;
  width: 120px;
  height: 22px;
}

.row-code-0 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 350px);
  top: 624px;
  width: 250px;
  z-index: 1;
}
.row-code-1 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 350px);
  top: 754px;
  width: 250px;
  height: 39px;
  z-index: 1;
}
.row-code-2 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 350px);
  top: 880px;
  width: 250px;
  height: 53px;
  z-index: 1;
}
.row-code-3 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 350px);
  top: 1014px;
  width: 250px;
  height: 53px;
  z-index: 1;
}
.row-code-4 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 350px);
  top: 1144px;
  width: 250px;
  height: 53px;
  z-index: 1;
}
.row-code-5 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 350px);
  top: 1275px;
  width: 250px;
  height: 53px;
  z-index: 1;
}
.row-code-6 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 350px);
  top: 1404px;
  width: 250px;
  height: 53px;
  z-index: 1;
}
.row-code-7 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 350px);
  top: 1534px;
  width: 250px;
  height: 53px;
  z-index: 1;
}
.row-code-8 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 350px);
  top: 1664px;
  width: 250px;
  height: 53px;
  z-index: 1;
}
.row-code-9 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 350px);
  top: 1794px;
  width: 250px;
  height: 53px;
  z-index: 1;
}

.display-name {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 21.87px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 490px);
  top: 533px;
  width: 177px;
  height: 45px;
}
.level-completed {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 21.87px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 240px);
  top: 533px;
  width: 119px;
}
.current-score {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 21.87px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 60px);
  top: 533px;
  width: 149px;
}

.overlay-used {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 21.87px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 110px);
  top: 533px;
  width: 119px;
}
.logged-in {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 21.87px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 220px);
  top: 533px;
  width: 79px;
}
.last-login {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 21.87px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 370px);
  top: 533px;
  width: 169px;
}

.type {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 21.87px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 220px);
  top: 533px;
  width: 169px;
}

.blocked {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 21.87px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 180px);
  top: 533px;
  width: 169px;
}

.partner-or-affiliate {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 21.87px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 524px);
  top: 533px;
  width: 169px;
}

.row-photo {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  position: absolute;
  left: calc(50% - 615px);
  top: 602px;
  z-index: 1;
  cursor: pointer;
}

.row-display-name {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 490px);
  top: 622px;
  max-width: 200px;
  width: 200px;
  height: 53px;
  z-index: 1;
}

.row-level-completed {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 240px);
  top: 622px;
  max-width: 200px;
  width: 200px;
  height: 53px;
  z-index: 1;
}
.row-current-score {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 66px);
  top: 622px;
  max-width: 200px;
  width: 200px;
  height: 53px;
  z-index: 1;
  cursor: pointer;
}
.row-overlay-used {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 102px);
  top: 622px;
  max-width: 200px;
  width: 200px;
  height: 53px;
}

.row-logged-in {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 224px);
  top: 622px;
  max-width: 200px;
  width: 200px;
  height: 53px;
  z-index: 1;
}

.row-type {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 220px);
  top: 622px;
  width: 200px;
  height: 53px;
  z-index: 1;
}

.row-last-login {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 370px);
  top: 622px;
  width: 200px;
  height: 53px;
  z-index: 1;
}

.row-blocked {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 190px);
  top: 622px;
  max-width: 200px;
  width: 200px;
  height: 53px;
  z-index: 1;
}

.row-partner-or-affiliate {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 524px);
  top: 622px;
  width: 200px;
  height: 53px;
  z-index: 1;
}

.no-mobile-view {
  visibility: hidden;

  @media only screen and (max-height: 900px) {
    visibility: visible;
    color: #000000;
    text-align: center;
    font: 900 23.14px/25.23px 'RoobertRegular', sans-serif;
    position: absolute;
    left: 10px;
    top: 420px;
    height: 57px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}
.score-overlay {
  z-index: 999 !important;
}
</style>
